<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper company-detail-drawer">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">Change Access URL</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4"
						v-on:click="updateCompanySetting()"
						>Save</v-btn
					>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<p class="mt-0">
						You can change the access URL the way you want. Existing URL will stop working eventually.
					</p>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Currency</label>
						</div>
						<v-autocomplete
							:items="currencyList"
							item-text="text"
							item-value="value"
							depressed
							hide-details
							outlined
							v-model="company.currency"
						></v-autocomplete>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Time Zone</label>
						</div>
						<v-autocomplete
							:items="time_zoneList"
							item-text="text"
							item-value="value"
							depressed
							hide-details
							outlined
							v-model="company.time_zone"
						></v-autocomplete>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "LocalInformation",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		companyInfo: {
			type: Object,
		},
	},
	watch: {
		companyInfo: {
			handler(param) {
				this.company.currency = param.currency;
				this.company.time_zone = param.time_zone;
			},
		},
	},
	data() {
		return {
			currencyList: [],
			time_zoneList: [],
			company: {
				currency: null,
				time_zone: null,
			},
		};
	},
	methods: {
		getSettingField() {
			this.$store
				.dispatch(GET, { url: `setting-field` })
				.then((data) => {
					this.currencyList = data.currency;
					this.time_zoneList = data.zone;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		updateCompanySetting() {
			this.pageLoading = true;
			this.$store
				.dispatch(PATCH, {
					url: `company-locale-setting/${this.companyInfo.id}`,
					data: this.company,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Company Updated Successfully.",
						},
					]);
					this.$emit("close", true);
					this.$emit("updateLocaleInfo", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
	mounted() {
		this.getSettingField();
	},
};
</script>

<style scoped></style>
