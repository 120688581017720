<template>
	<div>
		<v-layout class="py-3 px-3">
			<v-flex class="">
				<div class="fw-600 sub-heading primary--text">Company Details</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="d-flex justify-content-end">
				<v-btn class="" dense tile v-on:click="goBack">
					<v-icon class="ml-1">mdi-arrow-left</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>
		<div class="compay-tab-details mt-2">
			<v-tabs-items v-model="companyTab">
				<v-tab-item value="company_detaiils">
					<div class="company_detaiils-item">
						<div class="sec-1 pa-4 blue lighten-5 d-flex">
							<div>
								<div class="company-logo mr-5" v-if="companyInfo.company_image">
									<img :src="companyInfo.company_image" alt="logo" width="100" />
								</div>
								<div class="company-logo mr-5" v-else>
									<img src="media/custom-svg/companylogo.svg" alt="" />
								</div>
								<div
									class="primary--text text-center mr-5 text-uppercase fw-600 mt-2 cursor-pointer"
									v-on:click="selectFile()"
								>
									change
								</div>
							</div>
							<div class="company-details">
								<div class="sub-heading text-capitalize pb-5">
									{{ companyInfo.company_name }}
									<v-btn class="mx-2" icon fab small v-on:click="open_dialog.company_detail = true">
										<v-icon class="primary--text">mdi-pencil</v-icon>
									</v-btn>
								</div>
								<div class="fw-500 mb-2">
									<v-icon class="primary--text mr-1">mdi-email-outline</v-icon>
									{{ companyInfo.company_email }}
								</div>
								<div class="fw-500 mb-2">
									<v-icon class="primary--text mr-1">mdi-phone-outline</v-icon>
									{{ companyInfo.phone }}
								</div>
								<div class="fw-500 mb-2">
									<v-icon class="primary--text mr-1">mdi-web</v-icon>
									{{ companyInfo.company_domain_name }}
								</div>
								<div class="fw-500 mb-2">
									<v-icon class="primary--text mr-1">mdi-map-marker-outline</v-icon>
									{{ companyInfo.address }}, {{ companyInfo.postal_code }} {{ companyInfo.country }}
								</div>
							</div>
						</div>

						<div class="sec-2 fw-500 mt-5">
							<div class="sub-heading text-capitalize">
								Locale Information
								<v-btn class="mx-2" icon small fab v-on:click="open_dialog.local_information = true">
									<v-icon class="primary--text">mdi-pencil</v-icon>
								</v-btn>
							</div>
							<div class="d-flex align-items-center mt-3">
								<div class="mw-150 px-5 text-right text-muted">Currency</div>
								<div class="" v-if="companyInfo.currency">{{ companyInfo.currency.toUpperCase() }}</div>
							</div>
							<div class="d-flex align-items-center mt-3">
								<div class="mw-150 px-5 text-right text-muted">Time Zone</div>
								<div class="" v-if="companyInfo.time_zone == 'gmt-india'">
									(GMT 5:30) India Standard Time (Asia/Kolkata)
								</div>
								<div class="" v-if="companyInfo.time_zone == 'gmt-malaysia'">
									(GMT 8:00) Malaysia Standard Time (Asia/Malaysia)
								</div>
								<div class="" v-if="companyInfo.time_zone == 'gmt-singapore'">
									(GMT 8:00) Singapore Standard Time (Asia/Singapore)
								</div>
							</div>
						</div>
					</div>
				</v-tab-item>
				<!-- <v-tab-item value="fiscal_year">
					<div class="px-4">
						<div class="sub-heading text-capitalize">Fiscal Year</div>
						<p>This page enables you to set the start month for your Fiscal Year.</p>
						<div class="mb-3 d-flex px-0 col-md-4">
							<div class="d-flex pt-1 pr-5">
								<label class="fw-500">Fiscal Year begins in</label>
							</div>
							<v-autocomplete
								:items="MonthList"
								depressed
								hide-details
								outlined
								v-model="select_month"
							></v-autocomplete>
						</div>
						<div class="mb-5 d-flex justify-content-end px-0 col-md-4">
							<v-btn tile depressed color="blue darken-4 white--text">Save</v-btn>
						</div>
					</div>
				</v-tab-item> -->
				<!-- <v-tab-item value="business_hour">
					<div class="px-4">
						<div class="sub-heading text-capitalize">
							Business Hours
							<v-icon class="primary--text px-2" v-on:click="open_dialog.business_hours = true"
								>mdi-pencil</v-icon
							>
						</div>
						<div class="col-md-8 px-0">
							<p>
								Business hours define the operational hours of your organization. Set business hours to help
								your employees ensure that the activities are carried out at the operational hours of your
								organization.
							</p>
						</div>
						<div class="d-flex align-items-center">
							<div class="mw-150 px-5 text-right text-muted">Week starts on :</div>
							<div class="">Monday</div>
						</div>
						<div class="d-flex align-items-center mt-3">
							<div class="mw-150 px-5 text-right text-muted">Business Days :</div>
							<div class="">Monday-Sunday</div>
						</div>
						<div class="d-flex align-items-center mt-3">
							<div class="mw-150 px-5 text-right text-muted">Business Hours :</div>
							<div class="">24 Hours</div>
						</div>
					</div>
				</v-tab-item> -->
				<!-- <v-tab-item value="holidays">
					<div class="px-4">
						<div class="sub-heading text-capitalize">Create Holidays</div>
						<div class="mb-3 mt-5 d-flex px-0 col-md-4">
							<div class="d-flex pt-1 pr-5">
								<label class="fw-500">Select year </label>
							</div>
							<v-autocomplete
								:items="yearList"
								depressed
								hide-details
								outlined
								v-model="year"
							></v-autocomplete>
						</div>
						<div class="mt-5">
							<div class="sub-heading text-capitalize mb-2">Holiday list</div>
							<div class="pa-4 blue lighten-5">
								<table>
									<tr>
										<td class="fw-500 px-2 py-1" width="300">Holiday Name</td>
										<td class="fw-500 px-2 py-1" width="200">Holiday Date</td>
									</tr>
									<tr v-for="(data, index) in holidayList" :key="index">
										<td class="fw-500 px-2 py-1" width="300">
											<v-text-field
												hide-details
												outlined
												placeholder="Holiday Name"
												v-model="abcd"
											></v-text-field>
										</td>
										<td class="fw-500 px-2 py-1" width="200">
											<div class="d-flex">
												<div>
													<v-menu
														v-model="date_menu"
														:close-on-content-click="false"
														transition="scale-transition"
														offset-y
														max-width="290px"
														min-width="auto"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																hide-details
																outlined
																placeholder="DD MM YYYY"
																v-model="abc"
																prepend-inner-icon="mdi-calendar"
																v-on="on"
																v-bind="attrs"
															></v-text-field>
														</template>
														<v-date-picker v-model="abc" no-title @input="date_menu = false"></v-date-picker>
													</v-menu>
												</div>
												<v-icon
													v-if="holidayList.length == index + 1"
													@click="addHoliday()"
													class="ml-3"
													color="green"
													>mdi-plus</v-icon
												>
												<v-icon v-else @click="removeHoliday(index)" class="ml-3" color="red">mdi-minus</v-icon>
											</div>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</v-tab-item> -->
			</v-tabs-items>
		</div>
		<UpdateCompanyDetail
			:drawer="open_dialog.company_detail"
			:company-info="companyInfo"
			v-on:close="open_dialog.company_detail = false"
			v-on:refreshCompany="getCompanySetting"
		></UpdateCompanyDetail>
		<!-- <AccessUrl
			:drawer="open_dialog.access_url"
			v-on:close="open_dialog.access_url = false"
		></AccessUrl> -->
		<LocalInformation
			:drawer="open_dialog.local_information"
			:company-info="companyInfo"
			v-on:updateLocaleInfo="getCompanySetting"
			v-on:close="open_dialog.local_information = false"
		></LocalInformation>
		<!-- <BusinessHours
			:drawer="open_dialog.business_hours"
			v-on:close="open_dialog.business_hours = false"
		></BusinessHours> -->
		<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"> </v-file-input>
	</div>
</template>
<script>
import UpdateCompanyDetail from "@/view/module/setting/company/component/UpdateCompanyDetail";
import LocalInformation from "@/view/module/setting/company/component/LocalInformation";
import { GET, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "CompayDetails",
	components: {
		UpdateCompanyDetail,
		LocalInformation,
	},
	data() {
		return {
			date_menu: false,
			open_dialog: {
				company_detail: false,
				access_url: false,
				local_information: false,
				business_hours: false,
			},
			companyTab: "company_detaiils",
			companyInfo: {},
			companyId: null,
		};
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getCompanySetting() {
			this.$store
				.dispatch(GET, { url: `company-setting/1` })
				.then((data) => {
					this.companyInfo = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		uploadFile(e) {
			let formData = new FormData();
			formData.append("files", e);
			this.$store
				.dispatch(POST, { url: `comapny-image-update/1`, data: formData })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Image updated successfully.",
						},
					]);
					this.getSingleUser();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
	},
	mounted() {
		this.getCompanySetting();
	},
};
</script>
