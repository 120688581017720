<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper company-detail-drawer">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">Company Details</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn tile depressed color="white--text" class="blue darken-4" v-on:click="updateCompany()"
						>Save</v-btn
					>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Company Name</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="company.company_name"></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Company Email</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="company.company_email"></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Phone</label>
						</div>
						<v-text-field
							append-icon="mdi-phone"
							depressed
							hide-details
							outlined
							v-model="company.phone"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Website</label>
						</div>
						<v-text-field
							append-icon="mdi-web"
							depressed
							hide-details
							outlined
							v-model="company.company_domain_name"
						></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Description</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="company.description"></v-text-field>
					</div>

					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Street</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="company.address"></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">City</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="company.city"></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">State</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="company.state"></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Zip code</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="company.postal_code"></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Country</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="company.country"></v-text-field>
					</div>
					<div class="mb-5 d-flex">
						<div class="d-flex pt-1 px-2 w-130">
							<label class="fw-500">Total Employee</label>
						</div>
						<v-text-field
							depressed
							hide-details
							outlined
							v-model="company.no_of_employees"
						></v-text-field>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import { PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "UpdateCompanyDetail",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		companyInfo: {
			type: Object,
		},
	},
	watch: {
		companyInfo: {
			handler(param) {
				this.company.company_name = param.company_name;
				this.company.phone = param.phone;
				this.company.company_domain_name = param.company_domain_name;
				this.company.company_email = param.company_email;
				this.company.description = param.description;
				this.company.address = param.address;
				this.company.city = param.city;
				this.company.state = param.state;
				this.company.postal_code = param.postal_code;
				this.company.country = param.country;
				this.company.no_of_employees = param.no_of_employees;
			},
		},
	},
	data() {
		return {
			company_name: null,
			members: null,
			company: {
				company_name: null,
				phone: null,
				company_domain_name: null,
				company_email: null,
				description: null,
				address: null,
				city: null,
				state: null,
				postal_code: null,
				country: null,
				no_of_employees: null,
			},
		};
	},
	methods: {
		updateCompany() {
			this.pageLoading = true;
			this.$store
				.dispatch(PATCH, {
					url: `company-setting/${this.companyInfo.id}`,
					data: this.company,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Company Updated Successfully.",
						},
					]);
					this.$emit("close", true);
					this.$emit("refreshCompany", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
};
</script>
<style scoped></style>
